<template>
  <div class="bg-white md:w-full md:max-w-5xl rounded-md">
    <div class="flex flex-col md:flex-row items-center">
      <div class="md:w-128 text-center bg-gray-10_0 p-6 rounded-md md:rounded-r-none border-r">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Showman Auctions</h2>
        <div class="mt-4 flex items-center text-4xl font-extrabold justify-center">
          +$399
          <!--<span class="mx-2 text-5xl font-medium text-gray-800">+</span>
          <div class="text-lg font-medium text-gray-800 text-center"> $0.30/entry for each entry over 1000</div>-->
        </div>
        <!--<p class="my-5 text-base text-center text-gray-700">This is an add-on to use alongside your show on the Showman Platform.</p>-->
        <p class="my-5 text-base text-center text-gray-700">This is an add-on to use alongside your show on the Showman Platform.</p>
        <div class="rounded-md shadow">
          <button onclick="fathom.trackEvent('Ask About Auction Clicked');" @click="showAuctionEmail = true" href="#" class="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-showman hover:bg-orange-500">
            Ask about Showman Auctions
          </button>
        </div>
        <p v-if="showAuctionEmail" class="mt-3 font-semibold">Send an email to Jason@showman.app to inquire about using Showman for your Auction!</p>

      </div>
      <div class="w-full p-6 text-center">
        <!--<img src="https://res.cloudinary.com/showman/image/upload/h_110,f_auto/v1719685486/Showman_Auctions_Black.png" class="h-20 mx-auto mb-2">-->
        <p class="text-xl font-medium mb-4">Everything you love about Showman, now available for your auction!</p>
        <div class="flex flex-col md:flex-row items-center">
          <div class="text-left">
            <ul role="list" class="space-y-4 px-2">
              <li class="flex items-start">
                <div class="flex-shrink-0">
                  <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p class="ml-3 text-base text-gray-700">Automatically create a sale order based on your show results in minutes.</p>
              </li>
              <li class="flex items-start">
                <div class="flex-shrink-0">
                  <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p class="ml-3 text-base text-gray-700">Quickly register buyers before the sale and then record bids in real time during the auction.</p>
              </li>
              <li class="flex items-start">
                <div class="flex-shrink-0">
                  <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p class="ml-3 text-base text-gray-700">Email invoices, print checks and do more in bulk to save time. Even allow buyers to pay their invoice online right from their phones.</p>
              </li>

              <li class="flex items-start">
                <div class="flex-shrink-0">
                  <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p class="ml-3 text-base text-gray-700">Add-on Support: Online interface for buyers to easily support your exhibitors through add-ons.</p>
              </li>
            </ul>
          </div>
          <!--<iframe class="rounded-lg mx-auto mt-4 sm:mt-0" src="https://player.vimeo.com/video/732759892?h=1791613136&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="250" height="164" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="General Overview.mp4"></iframe>-->
        </div>
        <!--<p class="mt-5 text-base text-center text-gray-700">This is an add-on to use alongside your show on the Showman Platform.</p>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showAuctionEmail: false
    }
  }
}
</script>

<style>

</style>
