<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="bg-gray-900">
      <div class="pt-12 sm:pt-16 lg:pt-20">
        <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="text-center">
            <h2 class="text-white md:text-center text-4xl lg:text-5xl font-bold leading-none sm:leading-tighter">
            A small price for more time and<br><!--, more sanity & <br>-->
            <span class="inline-flex relative text-white">
              <span aria-hidden="" class="absolute inset-0 bg-showman" style="transform: skewY(-1.3deg) scaleX(1.03) scaleY(1.05);"></span>
              <span class="relative">less stress</span>
            </span>
            at your next show.
            </h2>
            <p class="mt-4 text-xl font-light text-gray-300">
              Sign up today and find out just how easy running your livestock show can be!
            </p>
          </div>
        </div>
      </div>

      <div class="mt-12 sm:mt-12 pb-10 sm:pb-16 lg:mt-16">
        <div class="relative">
          <div class="absolute inset-0 h-3/4 bg-gray-900"></div>
          <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-12 lg:gap-5 lg:space-y-0">
              <div class="col-span-6 flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div>
                    <h3 class="text-center text-3xl font-semibold text-gray-900 sm:-mx-6" id="tier-growth">Basic<br><span class="text-lg text-gray-800">Jackpots and Weekend Breed Shows</span></h3>
                  </div>
                  <div class="mt-4 flex items-center text-6xl font-extrabold justify-center">
                    $299
                    <!--<span class="mx-2 text-5xl font-medium text-gray-800">+</span>
                    <div class="text-lg font-medium text-gray-800 text-center"> $0.30/entry for each entry over 1000</div>-->
                  </div>
                  <p class="mt-5 text-lg text-center text-gray-700">All the basic tools you need to run a livestock show. Ditch the headaches and get ready to smile during your next weekend show.</p>
                </div>
                <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-100 space-y-6 sm:p-10 sm:pt-6">
                  <ul role="list" class="space-y-4">
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">Includes 1000 Entries.<br><strong>*Additional entries over 1000 are $0.30/entry*</strong></p>
                    </li>

                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">Unlimited Exhibitors</p>
                    </li>
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">Fully customizable entry form to collect info on apparel, pens, bedding and much more.</p>
                    </li>
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">Support for up to 4 species.</p>
                    </li>
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">1 hr show setup call with a show specialist</p>
                    </li>
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">Phone number for show day support</p>
                    </li>
                  </ul>
                  <div>
                    <div class="rounded-md shadow">
                      <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-showman hover:bg-orange-500">
                        Create My Show
                      </a>
                    </div>
                    <p class="mt-3 text-xs text-center font-medium">Online payments have a 3.5% processing fee that can either be built into your entry fees or passed on directly to exhibitors.</p>
                  </div>
                </div>
              </div>

              <div class="col-span-6 flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6 text-center">
                  <div>
                    <h3 class="text-3xl font-semibold text-gray-900 sm:-mx-6" id="tier-growth">Elite<br><span class="text-lg text-gray-800">Fairs, Festivals & Expos</span></h3>
                  </div>
                  <div class="mt-4 flex items-center text-6xl font-extrabold justify-center">
                    $599
                    <!--<span class="ml-1 text-2xl font-medium text-gray-800"> + $0.30/entry</span>-->
                  </div>
                  <p class="mt-5 text-lg text-gray-700 text-center">All the tools you know and love plus extra support for those events that require more detailed setup and support.</p>
                </div>
                <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-100 space-y-6 sm:p-10 sm:pt-6">
                  <ul role="list" class="space-y-4">
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">Includes 1000 Entries.<br><strong>*Additional entries over 1000 are $0.30/entry*</strong></p>
                    </li>

                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">Unlimited Exhibitors</p>
                    </li>
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">Fully customizable entry form to collect info on apparel, pens, bedding and much more.</p>
                    </li>
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">Support for unlimited species</p>
                    </li>
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">Support for Static Exhibits. (Farm & Graden, Baking, Art etc.)</p>
                    </li>
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">Extra Support and setup assistance. Including a 1hr setup call as well as an additional meeting to review show day tools.</p>
                    </li>
                    <li class="flex items-start">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-base text-gray-700">Phone number for show day support</p>
                    </li>
                  </ul>
                  <div>
                    <div class="rounded-md shadow">
                      <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-showman hover:bg-orange-500">
                        Create My Show
                      </a>
                    </div>
                    <p class="mt-3 text-xs text-center font-medium">Online payments have a 3.5% processing fee that can either be built into your entry fees or passed on directly to exhibitors.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 max-w-md mx-auto lg:max-w-5xl ">
              <div class="p-5 text-white md:p-6 rounded-lg text-center border-2 border-gray-800" style="background-color: #0d203b;">
                <div class="flex flex-col md:flex-row md:items-center">
                  <div class="border-b md:border-0 p-3 w-full">
                    <h3 class="text-3xl font-semibold text-white" id="tier-growth">Enterprise Pricing</h3>
                    <p class="text-2xl font-medium mt-2 text-gray-200">Starting at $2000</p>
                  </div>
                  <div class="p-3 text-center md:border-l">
                    <p class="text-lg text-gray-100">Showman can work with shows of all scopes and size. If your show needs special approval, vendor registration or any additional paperwork we would love to have a conversation to learn more.</p>
                    <div class="mt-8">
                      <a class="btn btn-orange-outline " data-savvycal-embed href="https://savvycal.com/showman/enterprise">Contact Sales</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-showman py-10">
        <div class="max-w-5xl md:mx-auto mx-3">
          <div class="border-b-2 border-gray-200 mb-6">
            <h4 class="flex-shrink-0 pr-4 text-xl tracking-wider font-semibold uppercase text-white">
              Additional Packages
            </h4>
          </div>
          <ShowmanAuctionPricing id="auction"/>
        </div>
      </div>
      <div id="testimonials">
        <!--<div class="bg-gray-900 text-gray-100 p-8">
          <div class="max-w-6xl mx-auto px-3 h-64">
            <h2 class="text-2xl font-bold leading-snug lg:text-4xl lg:text-center">
                Veterans and first time show people agree!
              </h2>
              <p class="mt-4 lg:max-w-2xl lg:mx-auto lg:text-xl lg:text-center">
                Showman saves you time and keeps your show organized.
              </p>
          </div>
        </div>-->

        <div class="sm:flex w-full divide-y sm:divide-y-0 sm:divide-x divide-dashed divide-gray-400 my-12 text-center">
          <div class="sm:w-1/2 md:w-1/3">
            <div class="px-6 py-12 text-lg lg:text-xl leading-6 lg:leading-7">
              <img src="https://res.cloudinary.com/showman/image/upload/w_80/v1655138321/Testimonial_The_Classic.png" width="400" height="400" class="block w-20 h-20 rounded-lg mx-auto" alt="Chris" loading="lazy">
              <p class="flex-grow py-6 text-gray-200 lg:tracking-tight">“I cannot express to you enough how well Showman worked for our show. Your customer service was absolutely amazing and the way you were able to customize your program to fit our show was spectacular. We had a very smooth ran show and grossed more than ever before!”</p>
              <div class="text-lg text-white font-medium">The Classic/AGR Spring Showdown</div>
              <div class="text-base text-gray-300">Iowa State University Block & Bridle and AGR</div>
            </div>
          </div>

          <div class="sm:w-1/2 md:w-1/3">
            <div class="px-6 py-12 text-lg lg:text-xl leading-6 lg:leading-7">
              <img src="https://res.cloudinary.com/showman/image/upload/w_80/v1655138704/Testimonial_Boatman.png" width="400" height="400" class="block w-20 h-20 rounded-lg mx-auto" alt="Tracy" loading="lazy">
              <p class="flex-grow py-6 text-gray-200 lg:tracking-tight">“Just wanted to say thank you. The Show went off without a hitch. We'll be back next year! The app saved me a ton of time and stress! ”</p>
              <div class="text-lg text-white font-medium">Wendi Boatman</div>
              <div class="text-base text-gray-300">Boatman Club Lambs</div>
            </div>
          </div>

          <div class="sm:w-1/2 md:w-1/3">
            <div class="px-6 py-12 text-lg lg:text-xl leading-6 lg:leading-7">
              <img src="https://res.cloudinary.com/showman/image/upload/w_80/v1655139172/Testimonial_Mitten.png" width="400" height="400" class="block w-20 h-20 rounded-lg mx-auto" alt="Matt" loading="lazy">
              <p class="flex-grow py-6 text-gray-200 lg:tracking-tight">“We won’t put on a show without it! Having Showman kept our records clean and our show organized.
                    We were able to compile and finalize our show results the day after the show!
                    Thank you for developing this tool that will be so helpful to many!”</p>
              <div class="text-lg text-white font-medium">Lisa Douglas & Jodie Fath</div>
              <div class="text-base text-gray-300">Central Michigan Boer Goat Board Members after their first time hosting a livestock show</div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="mt-8 bg-gray-900  sm:mt-12 ">
        <div class="relative">
          <div class="absolute inset-0 h-1/2 bg-gray-900"></div>
          <div class="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex bg-white">
              <div class="flex-1 px-6 py-8 lg:p-12">
                <h3 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                  Everything you need to run a livestock show
                </h3>
                <p class="mt-6 text-base text-gray-600">
                  The event pricing includes all features and tools you will need to run a livestock show. We don’t have any tiered pricing structures, hidden fees or packages. Set up meetings and customer support come included with every show.
                  Every show that signs up to use showman will have it's own dedciated show hompage and admin dashboard. The show homepage is where exhibitors will view all the show info and enter the show. The Admin Dashboard is where you will manage your event info, entry data and run your show the day of.
                </p>
                <div class="mt-8">
                  <div class="flex items-center">
                    <h4 class="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
                      What's included
                    </h4>
                    <div class="flex-1 border-t-2 border-gray-200"></div>
                  </div>
                  <ul role="list" class="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                    <li class="flex items-start lg:col-span-1">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-sm text-gray-700">
                        **1000 Entries (see note below)
                      </p>
                    </li>

                    <li class="flex items-start lg:col-span-1">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-sm text-gray-700">
                        Unlimited show types for your event
                      </p>
                    </li>

                    <li class="flex items-start lg:col-span-1">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-sm text-gray-700">
                        Unlimited Exhibitors
                      </p>
                    </li>

                    <li class="flex items-start lg:col-span-1">
                      <div class="flex-shrink-0">
                        <svg class="h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p class="ml-3 text-sm text-gray-700">
                        Fully customizable entry form to collect info on apparel, pens, bedding and much more.
                      </p>
                    </li>
                  </ul>
                  <div id="fine-print" class="mt-4">
                    <p class="text-sm text-gray-700 sm:text-center">**Showman supports shows of all sizes. Each entry over 1000 has an additional cost of $0.30/entry.</p>
                  </div>
                </div>
              </div>

              <div class="py-8 px-6 text-center bg-gray-100 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12 bg-gray-100 lg:max-w-xs">
                <p class="text-lg leading-6 font-medium text-gray-900">
                  For your first 1000 entries
                </p>
                <div class="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
                  <span>
                    $299
                  </span>
                  <span class="ml-3 text-xl font-medium text-gray-500">
                    USD
                  </span>
                </div>
                <p class="mt-4 font-medium text-gray-500 text-xs">
                    Additional entries over 1000 are $0.30/entry
                </p>
                <div class="mt-6">
                  <div class="rounded-md shadow">
                    <a href="/shows/#/new-show-signup" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-showman hover:bg-orange-500">
                      Create My Show
                    </a>
                  </div>
                </div>
                <div class="mt-4 text-xs">
                  <p class="font-medium">
                    Online payments have a 3.5% processing fee that can either be built into your entry fees or passed on directly to exhibitors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->

      <div class="max-w-6xl mx-auto mt-16 pb-16 sm:pb-20 lg:pb-28 px-4 sm:px-6 lg:px-8">
        <h4 class="flex-shrink-0 pr-4 text-lg tracking-wider font-semibold uppercase text-white">
          Available Add On's
        </h4>
        <div class="flex-1 border-t-2 border-gray-200"></div>
        <div class="relative mt-4 lg:mt-8 lg:flex lg:gap-8 lg:items-center">
          <div class="relative lg:w-1/2">
            <h3 class="text-xl font-extrabold tracking-tight sm:text-2xl text-gray-100">
              Integrated Sponsorship Form
            </h3>
            <p class="mt-3 text-lg text-gray-300">
               Collect show sponsors information and payments right from your show homepage.
            </p>

            <div class="py-8 px-4 sm:py-16 sm:px-6 lg:bg-none lg:px-0 lg:pl-8 lg:flex lg:items-center lg:justify-end">
              <div class="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
                <div>
                  <h2 class="sr-only">Price</h2>
                  <h2 class="text-3xl text-white text-center font-bold mb-2">Get sponsors while you sleep</h2>
                  <p class="relative grid grid-cols-2">
                    <span class="flex flex-col text-center">
                      <span class="text-5xl font-extrabold text-white tracking-tight line-through ">1.5%</span>
                      <span class="mt-2 text-base font-medium text-indigo-200"><span class="font-bold">NEW FOR 2022</span><br> No additional fees for collecting sponsors</span>
                      <span class="sr-only">plus</span>
                    </span>
                    <span class="pointer-events-none absolute h-12 w-full flex items-center justify-center" aria-hidden="true">
                      <svg class="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                      </svg>
                    </span>
                    <span>
                      <span class="flex flex-col text-center">
                        <span class="text-5xl font-extrabold text-white tracking-tight">3.5%</span>
                        <span class="mt-2 text-base font-medium text-indigo-200">CC Processing</span>
                      </span>
                    </span>
                  </p>
                  <!--<p class="text-center text-sm text-indigo-200 mt-3">
                    (Of sponsorship money collected through Showman)
                  </p>-->
                </div>
                <ul role="list" class="rounded overflow-hidden grid gap-px sm:grid-cols-2">
                  <li class="bg-indigo-800 bg-opacity-50 py-4 px-4 flex items-center space-x-3 text-base text-white">
                    <!-- Heroicon name: outline/check -->
                    <svg class="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span>Unlimited sponsor levels</span>
                  </li>

                  <li class="bg-indigo-800 bg-opacity-50 py-4 px-4 flex items-center space-x-3 text-base text-white">
                    <!-- Heroicon name: outline/check -->
                    <svg class="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span>No cost to set up</span>
                  </li>

                  <li class="bg-indigo-800 bg-opacity-50 py-4 px-4 flex items-center space-x-3 text-base text-white">
                    <!-- Heroicon name: outline/check -->
                    <svg class="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span>CC processing fees can be built in or passed on</span>
                  </li>

                  <li class="bg-indigo-800 bg-opacity-50 py-4 px-4 flex items-center space-x-3 text-base text-white">
                    <!-- Heroicon name: outline/check -->
                    <svg class="h-6 w-6 text-indigo-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span>Sponsors are automatically added to show homepage</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="lg:w-1/2 mt-10 lg:pl-16 -mx-4 relative lg:mt-0" aria-hidden="true">
            <img class="relative mx-auto rounded-lg shadow-lg px-4 md:px-0" width="490" src="https://res.cloudinary.com/showman/image/upload/c_scale,w_490,f_auto/v1633366701/sponsor_show_screenshot.png" alt="">
          </div>
        </div>
      </div>

    </div>

    <div class="bg-indigo-600">
      <div class="max-w-4xl mx-auto pt-8 pb-10 mb-16 ">
        <!--<h4 class="text-4xl sm:text-5xl text-white font-extrabold text-center tracking-tight mb-4">Online payments</h4>-->
        <p class="text-4xl sm:text-5xl text-white font-extrabold text-center tracking-tight">Online Payments</p>
        <p class="text-3xl text-indigo-100 text-center mb-3">What's up with the 3.5% fee?</p>
        <p class="sm:text-lg leading-5 text-center text-white p-2 mb-8">
          Convenience comes at a cost. In order to accept payments via credit card, payment processors charge businesses a small processing fee.
          This is true when getting gas, eating out or shopping online.
          Most businesses build that cost into their prices, while also factoring in the convenience of getting access to their funds sooner and not having to deal with physical cash or checks.
          With Showman you have access to our integrated online payment system, making it easier for exhibitors to pay and for you to get paid.
        </p>

          <div class="text-center tracking-tight sm:mx-0">
            <span class="inline-flex relative">
              <span aria-hidden="" class="absolute inset-0 bg-white opacity-75" style="transform: skewY(-2.6deg) scaleX(1.0) scaleY(1.2);"></span>
              <span class="relative p-4">
                <p class="text-xl sm:text-2xl text-black font-bold tracking-tight mb-2">Worried about paying the fee? You have a few options:</p>
                <div class="text-left px-2 sm:px-12 space-y-2 sm:text-lg">
                  <p>1. Pass the fee along. Exhibitors will have the fee tacked onto their invoice when they checkout.</p>
                  <p>2. Factor the fee into the costs of running the show. Some easy ways to do this are: increase prices by $1, charge a pen fee or get an extra sponsor for your show.</p>
                </div>
              </span>
            </span>
          </div>
      </div>
    </div>

    <!--<div id="Pricing" class="max-w-6xl mx-auto px-3">
      <div class="text-white md:text-center text-4xl lg:text-5xl font-bold leading-none sm:leading-tighter pb-10">
        Sign up today and find out just how easy running your livestock show can be.
      </div>
      <div class="flex flex-col justify-center items-center lg:flex-row pb-20">
        <div class="max-w-xl lg:w-1/2 md:mr-2 md:ml-4 pb-10 md:pb-0 z-10">
          <div class="w-full bg-gray-200 text-center p-6 rounded-t">
            <h3 class="text-gray-900 uppercase tracking-wide text-lg sm:text-xl text-center font-bold my-0">
              Pricing
            </h3>
          </div>
          <div class="w-full bg-white p-4 rounded-b">
            <div class="text-center py-6 lg:py-4">
              <span class="line-through opacity-75 inline-flex items-center font-display text-xl md:text-2xl font-semibold text-gray-600 mr-3 sm:mr-4">
                <span class="text-xl">$</span><span>349</span>
              </span>
              <span class="inline-flex items-center font-display text-5xl md:text-6xl font-bold text-black mr-3 sm:mr-4">
                <span class="text-2xl md:text-3xl">$</span><span>299</span>
              </span>
              <span class="inline-flex items-center font-display text-xl md:text-2xl font-bold text-grey-dark mr-3 sm:mr-4">
                USD
              </span>
              <div class="text-xs text-gray-700">3.5% credit card processing fee can either be absorbed or passed on.</div>
            </div>
            <ul class="list-reset px-2 sm:px-6 text-sm sm:text-sm md:text-base pb-6">
              <li class="flex items-center mb-4">
                <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
                <span>One event <strong>with up to 1000 individual entries.</strong></span>
              </li>
              <li class="flex items-center mb-4">
                <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
                <span>Set up and configure an <strong>unlimited number of individual shows </strong> for the event.</span>
              </li>
              <li class="flex items-center mb-4">
                <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
                <span><strong>Fully customizable</strong> show entry settings. Includes support for <strong>all variety of livestock</strong> and show types.</span>
              </li>
              <li class="flex items-center mb-4">
                <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
                <span>A <strong>complete set</strong> of show day tools that take you from check in to recording the final show results.</span>
              </li>
              <div class="hr"></div>
              <p class="text-gray-900 uppercase tracking-wide sm:text-lg text-center font-bold pb-4">
                Launch Exclusives
              </p>
              <li class="flex items-center mb-4">
                <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
                <span><strong>Personalized one-on-one onboarding.</strong> Ensure that your shows settings and presentation are exactly how you want them.</span>
              </li>
              <li class="flex items-center mb-4">
                <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
                <span><strong>Guaranteed Pricing.</strong> All new features developed between now and your show are included at no additional cost.</span>
              </li>
              <li class="flex items-center mb-4">
                <span class="mr-4"><svg class="h-5 w-5 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g fill="none" fill-rule="evenodd"><circle cx="10" cy="10" r="10" fill="#FBD38D"></circle><polyline stroke="rgb(246, 153, 63)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" points="6 10 8.667 12.667 14 7.333"></polyline></g></svg></span>
                <span><strong>Premium Support</strong> including phone and weekend support.</span>
              </li>
            </ul>
            <router-link :to="'signup'" class="btn btn-orange w-full block text-center">Sign up</router-link>
          </div>
        </div>
        <div class="lg:w-2/5 md:ml-6">
          <div class="py-4 mt-12">
            <h3 class="text-white tracking-wide text-2xl text-center font-bold pb-4">
              Do you run a larger show/event?
            </h3>
            <p class='text-center text-xl text-gray-200 pb-8'>Showman is built to handle shows of all sizes. But before getting started let's have a quick chat to discuss your events unique requirements.</p>
            <a :href="`mailto:${supportEmail}`" target="_blank" class="btn btn-gray-outline w-64 block mx-auto text-center">Contact Josh</a>
          </div>
        </div>
      </div>
    </div>-->

    <FAQ />
    <div class="bg-gray-900">
      <div class="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-100 sm:text-4xl">
          <span class="block">Ready to get started on your show?</span>
        </h2>
        <div class="mt-8 flex justify-center">
          <div class="inline-flex rounded-lg shadow">
            <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-bold rounded-lg text-white bg-showman hover:bg-orange-500">
              Start my show set up!
            </a>
          </div>
          <!--<div class="ml-3 inline-flex">
            <a href="/#/pricing/#faq" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-lg text-gray-900 bg-white hover:bg-gray-200">
              Ask a question
            </a>
          </div>-->
        </div>
        <p onclick="fathom.trackGoal('HIJDFCOU', 0);" class="text-gray-100 mt-8">Still need to see more? <a class="link font-bold" data-savvycal-embed href="https://savvycal.com/showman/demo">Schedule a demo</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import FAQ from './FAQ.vue'
import ShowmanAuctionPricing from './ShowmanAuctionPricing.vue'
export default {
  components: {
    FAQ,
    ShowmanAuctionPricing
  },
  data () {
    return {
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL
    }
  }
}
</script>

<style>

</style>
