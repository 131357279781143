<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-gray-900">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="lg:max-w-2xl lg:mx-auto lg:text-center">
        <h2 class="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
          Frequently asked questions
        </h2>
        <p class="mt-4 text-gray-400">Can’t find the answer you’re looking for? Reach out to our <a class="link" href="mailto:support@showman.app" target="_blank"> support team.</a></p>
      </div>
      <div class="mt-12 sm:mt-20">
        <dl class="space-y-6 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
          <div v-for="(faq, i) in faqs" :key="i">
            <dt class="font-semibold text-white">
              {{ faq.question }}
            </dt>
            <dd class="mt-3 text-gray-400">
              {{ faq.answer }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
const faqs = [
  {
    question: 'What counts as an entry?',
    answer:
      'You can think of an entry as being any time an exhibitor enters the ring or has a placing recorded. As an example consider a 2 ring show; if an exhibitor brings 2 animals and shows them each in both rings and participates in 1 showmanship class, that would be a total of 5 entries.'
  },
  {
    question: 'Do I need to pay every year/time I use this for an event?',
    answer:
      'Yes, each show is set up as a separate event. If you host multiple shows a year or if you would like to use Showman for subsequent years you will need to create a new show in the system each time.'
  },
  {
    question: 'Is there an app?',
    answer:
      `No, there aren't any mobile apps available for Showman. Showman is web based and can be accessed on any device with an internet connection. All pages are designed with mobile in mind and work well on phones and tablets.`
  },
  {
    question: 'Will I need an internet connection at my show?',
    answer:
      `Yes, Showman does require an internet connection. If there isn't wifi available at your fairgrounds, many shows have had great success using a mobile hotspot (either their cell phone or a dedicated device).`
  },
  {
    question: 'What happens if my show goes over 1000 entries?',
    answer:
      `If you have a large show that exceeds 1000 entries, don't worry; everything will keep on working for you. Showman supports shows of all sizes and is built to handle as many entries as you need. However, the initial package price ($299 or $599) is only for the first 1000 entries. After that there is an additional cost of $0.30/entry.`
  },
  {
    question: 'Do I need to pay ahead of time?',
    answer:
      `No, you don't need to pay ahead of time. One of the payment options you have to pay for your show is to pay using your show entry fees. If this option is selected, the fee will be taken out before your funds are transferred to you. This is a great option for new shows.`
  },
  {
    question: 'Can I accept other payment types?',
    answer:
      'Yes, you have the option of accepting other "offline" payment types. You can manually record payments either at the show or before the show in the admin dashboard. However, the only fully integrated payment option is the online CC payments. We strongly recommend you consider utlizing the online payments as much as possible to save you time and to take full advantage of all the features.'
  },
  {
    question: 'How do I get paid the entry fee money?',
    answer:
      'In the admin dashboard for your show you will be able to configure a bank account to deposit your entry fees into. Payouts can either be deposited as a lump sum after the show is over, or you can have funds deposited earlier as needed and as they become available.'
  },
  {
    question: 'Can I collect other information from exhibitors such as apparel orders or stalling/camping info?',
    answer: `Yes, you can add in as many additional "Entry Questions" to ask your exhibitors as you need. The questions can have a price associated with them if you would like to collect money from them ahead of time. Or they can be just informational for helping you prepare for the show.`
  }
  // More questions...
]

export default {
  data () {
    return {
      faqs
    }
  }
}
</script>
